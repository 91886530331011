export const areaVO = function () {
  return {
    id: "",
    name: "",
    code: "",
    description: "",
    fullName: "",
    parentId: null,
    parentIds: "",
    sorter: 0,
    longitude: "",
    latitude: ""
  }
}
